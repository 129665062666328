import * as THREE from 'three';
import OrbitControls from 'orbit-controls-es6';


// Fragment controls color
// Be carefull with many computations regarding performance - gets calculated for each pixel
import fragment from "./shaders/fragment.glsl";
// Vertex controls position, shape and geometry
// You can usually get away with computations without affecting performance
import vertex from "./shaders/vertex.glsl";


export default class Sketch {
	constructor (options) {
		this.time = 0;
		
		this.container = options.dom;
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
		
		this.scene = new THREE.Scene();
		
		this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 0.01, 10);
		this.camera.position.z = 1;
		
		this.renderer = new THREE.WebGLRenderer({
			antialias: true
		});
		
		this.container.appendChild(this.renderer.domElement);
		
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		
		this.addObjects();
		this.resize();
		this.setupResize();
		this.render();
	}

	setupResize () {
		window.addEventListener('resize', this.resize.bind(this));
	}

	resize () {
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
		this.renderer.setSize(this.width, this.height);
		this.camera.aspect = this.width / this.height;
		this.camera.updateProjectionMatrix();
	}

	addObjects () {
		// Adding multiple and different kinds of objects and shaders to the void
		
		// Adding box
		this.boxGeometry = new THREE.BoxGeometry(0.4,0.4,0.4 , 4,4,4);
		// Higher numbers makes object larger    ==^===^===^
		// Higher numbers gives more detail    ================^=^=^
		// Higher numbers also means more calculations
		this.boxMaterial = new THREE.MeshNormalMaterial();
		this.boxMaterial = new THREE.ShaderMaterial({
			fragmentShader: fragment,
			vertexShader: vertex,
			wireframe: true
		});

		this.boxMesh = new THREE.Mesh(this.boxGeometry, this.boxMaterial);
		// this.scene.add(this.boxMesh);
		
		// Adding square
		this.squareGeometry = new THREE.PlaneBufferGeometry(4,4 , 120,120);
		// Higher numbers makes object larger    ===========^=^
		// Higher numbers gives more detail    =====================^===^
		// Higher numbers also means more calculations
		this.squareMaterial = new THREE.MeshNormalMaterial();
		this.squareMaterial = new THREE.ShaderMaterial({
			fragmentShader: fragment,
			side: THREE.DoubleSide,
			uniforms: {
				time: {
					value: 0
				}
			},
			vertexShader: vertex,
			// wireframe: true
		});
		this.squareMesh = new THREE.Mesh(this.squareGeometry, this.squareMaterial);
		this.scene.add(this.squareMesh);
	}

	render () {
		this.time += 0.05;
		
		// Adding box
		this.boxMesh.rotation.x = this.time / 2000;
		this.boxMesh.rotation.y = this.time / 1000;
		
		// Adding square
		this.squareMesh.rotation.x = this.time / 2000;
		this.squareMesh.rotation.y = this.time / 1000;
		this.squareMaterial.uniforms.time.value = this.time; // Let's you use "time" in the vertex shader

		this.renderer.render(this.scene, this.camera);

		window.requestAnimationFrame(this.render.bind(this));
	}
}
new Sketch({
	dom: document.getElementById('container')
});